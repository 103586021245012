@use "../../../assets/scss/main.scss" as *;

.blogSection {
  display: flex;
  flex-direction: column;
  gap: 48px;

  flex: 1 0 auto;

  @include iftablet {
    margin: 32px 0 118px;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 26.4px;

  text-transform: uppercase;

  color: $whiteDark;

  @include ifmobile {
    text-transform: none;
  }
}

.addingButton {
  padding: 4px;
}

.blogItemsWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 124px;
  row-gap: 40px;

  @include iftablet {
    grid-template-columns: auto;
  }
}

.emptyTextWrapper {
  display: flex;
  flex-direction: column;

  p {
    font-family: $font-inter;
    font-size: 22px;
    font-weight: 500;
    line-height: 30.8px;

    text-align: center;

    color: $whiteGray;

    @include ifmobile {
      font-family: Lexend;
      font-size: 16px;
      line-height: 22.4px;
    }
  }
}

.emptyWrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
