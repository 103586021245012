@use "../../../../assets/scss/main.scss" as *;

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;

  background-color: $primary;

  border-radius: 16px;
  padding: 40px;
}

.reviewText {
  font-size: 32px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -1.5px;
  text-align: start;

  color: $white;

  @include ifmobile {
    font-size: 16px;
    font-weight: 500;
    line-height: 20.8px;
    text-align: left;
  }
}

.name {
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;

  margin-bottom: 24px;

  color: $white;
}

.rating {
  padding-top: 24px;
  border-top: 1px solid $greenLight;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stars {
  display: flex;
  align-items: center;
}

.separator {
  color: $custom-green-light;
}

.date {
  color: $grayLight;

  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}
