@use "../../../../assets/scss/main.scss" as *;

.container {
  display: flex;
  gap: 32px;

  background: $black;

  padding: 32px;

  border-radius: 12px;

  cursor: pointer;

  height: 250px;

  @include iftablet {
    flex-direction: column;
    gap: 8px;

    height: auto;

    padding: 16px;
  }
}

.bestArticleContainer {
  background: $primary;
}

.imageWrapper {
  img {
    width: 336px;
    height: 100%;

    object-fit: cover;

    @include iftablet {
      width: 100%;
      height: 250px;
    }

    @include ifmobile {
      width: 100%;
      height: 180px;
    }
  }
}

.blogInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include iftablet {
    gap: 8px;
  }
}

.title {
  font-family: Lexend;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -0.5px;

  color: $whiteGray;

  @include ifmobile {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: -0.5px;
  }
}

.description {
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;

    color: $whiteGray;

    opacity: 0.7;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 18px;
    line-height: 20px;
  }

  @include ifmobile {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
}

.author {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;

  color: $whiteGray;

  opacity: 0.7;

  @include iftablet {
    padding-top: 16px;
  }

  @include ifmobile {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
}
