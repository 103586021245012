@use "../../../../assets/scss/main.scss" as *;

.video {
  display: flex;
  align-items: center;
  gap: 24px;

  width: 100%;

  padding: 28px 0;

  border-bottom: 1px solid $custom-white-light;

  @include ifmobile {
    flex-direction: column;
    gap: 16px;
  }
}

.videoLoader {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
}

.videoPlayer {
  min-width: 155px;
  height: 89px;

  border-radius: 5px;

  overflow: hidden;

  position: relative;

  img {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  @include ifmobile {
    width: 100%;
    height: auto;
  }
}

.clickablePlayer {
  cursor: pointer;
}

.videoInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: 100%;
}

.pricesWrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  p {
    font-family: $font-inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 25.2px;

    color: $whiteDark;
    text-wrap: nowrap;
  }

  @include ifmobile {
    flex-direction: column;
    gap: 4px;

    p {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
  }
}

.diagonalLine {
  position: relative;
}

.diagonalLine::before {
  content: "";
  position: absolute;
  top: 50%;
  width: 100%;
  height: 2px;
  background-color: $redDark;
  opacity: 1;
  rotate: -20deg;
}

.pricesWrapper .oldPrice {
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
}

.videoTitleWrapper {
  display: flex;
  justify-content: space-between;
}

.videoTitle {
  font-family: $font-inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;

  color: $whiteGray;

  @include ifmobile {
    font-size: 16px;
    line-height: 16px;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 15.4px;

    opacity: 0.8;

    @include ifmobile {
      font-size: 12px;
      line-height: 13.2px;
    }
  }
}

.time {
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;

  color: $whiteGray;
}

.description {
  font-family: $font-inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;

  opacity: 0.7;

  color: $whiteGray;

  @include ifmobile {
    font-size: 12px;
    line-height: 15.6px;
  }
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  button {
    padding: 4px 16px;
    @include ifmobile {
      padding: 4px 8px;
    }
  }

  .buyButton {
    padding: 4px 30px;
  }

  @include ifmobile {
    flex-direction: row;

    width: 100%;

    button {
      width: 100%;
    }
  }
}

.removingText {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;

  text-align: center;

  opacity: 0.7;

  color: $neutral;
}

.removingVideoText {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;

  text-align: center;

  opacity: 0.7;

  color: $neutral;
}
