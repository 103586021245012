@use "../../assets/scss/main.scss" as *;

.header {
  padding: 19.5px 0 5.5px;

  z-index: 3;

  position: fixed;
  width: 100%;

  @include ifmobile {
    z-index: 1039;
  }
}

.guestHeader {
  background: $primary-black;
}

.userHeader {
  background: $blackLight;
}

.adminHeader {
  background: $blackLight;
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;

  position: relative;
  z-index: 2;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  max-width: $main-container-width;
  width: 100%;

  padding: 0 15px;

  @include ifdesktopM {
    max-width: $tablet-container-width;
  }

  @include iftablet {
    max-width: $mobile-container-width;

    gap: 0;
    padding: 0 12px;

    img {
      z-index: 3;
    }
  }

  @include ifmobile {
    max-width: min(100%, 500px);
  }
}

.picture {
  img {
    display: flex;

    width: 172px;
    height: 50px;
    object-fit: cover;

    cursor: pointer;
  }
}

.mobileCartWrapper {
  display: flex;
  align-items: center;
  gap: 24px;
}

.menuToggle {
  display: none;
}

.menuToggle:checked + .menuBtn > span {
  transform: rotate(45deg);
}

.menuToggle:checked + .menuBtn > span::before {
  top: 0;
  transform: rotate(0deg);
}

.menuToggle:checked + .menuBtn > span::after {
  top: 0;
  transform: rotate(90deg);
}

.menuToggle:checked ~ .navigationLinks {
  left: 0;

  justify-content: center;
  align-items: center;
}

.menuBtn {
  display: none;

  right: 0;

  transition-duration: 0.25s;

  @include ifdesktopM {
    display: flex;
    position: relative;

    width: 24px;

    position: absolute;
    top: 24px;
    right: 12px;

    cursor: pointer;

    z-index: 3;

    height: 24px;
  }
}

.menuBtn > span,
.menuBtn > span::before,
.menuBtn > span::after {
  display: block;
  position: absolute;
  width: 24px;
  height: 2px;
  background: $white;
  transition-duration: 0.25s;
}

.menuBtn > span::before {
  content: "";
  top: -8px;
}

.menuBtn > span::after {
  content: "";
  top: 8px;
}

.navigationLinks {
  display: flex;
  align-items: center;
  position: relative;
  gap: 16px;

  @include ifdesktopM {
    flex-direction: column;
    gap: 0;

    position: fixed;

    margin-right: 0;

    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    padding: 80px 24px;

    background: $primary-black;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);

    transition-duration: 0.25s;
    z-index: 2;
  }
}

.generalNavigationLinks {
  display: flex;

  @include ifdesktopM {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    width: 100%;

    padding-top: 16px;
    padding-bottom: 32px;
  }
}

.userNavigationLinks {
  display: flex;

  @include ifdesktopM {
    order: -1;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    width: 100%;
  }
}

.navigationLink {
  display: flex;
  align-items: center;

  padding: 16px;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  text-transform: capitalize;

  color: $whiteDark;

  opacity: 0.8;

  cursor: pointer;

  &:is(:hover, :active) {
    opacity: 1;
  }

  white-space: nowrap;

  @include ifdesktopM {
    font-size: 14px;

    padding: 12px 6px;
  }

  @include ifdesktopM {
    font-family: $font-inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 25.39px;
    letter-spacing: -0.03em;

    padding: 0;

    opacity: 1;

    color: $whiteBlue;
  }
}

.arrowIcon {
  display: flex;
}

.coursesMenu {
  display: none;
}

.courseNavigationLink {
  &:is(:hover, :active) .coursesMenu {
    display: block;
  }

  @include ifdesktopM {
    width: 100%;
  }
}

.separator {
  display: none;

  @include ifdesktopM {
    display: block;

    width: 100%;
    height: 1px;

    margin: 16px 0;

    background: $whiteGray;

    opacity: 0.2;
  }
}

.currLangWrapper {
  display: flex;
  gap: 24px;

  .currencySelector {
    margin-left: auto;
    @include ifdesktopM {
      padding-bottom: 48px;
    }

    @include ifmobile {
      margin: 0;
    }
  }
}

.currencySelector {
  @include ifdesktopM {
    padding-bottom: 48px;
  }
}

.activeNavigationLink {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  opacity: 1;

  color: $white;

  @include ifdesktopM {
    font-family: $font-inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 25.39px;
    letter-spacing: -0.03em;

    opacity: 1;

    color: $whiteBlue;
  }
}

.button {
  @include ifdesktopM {
    display: none;
  }
}

.pictureWrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;

  cursor: pointer;

  img {
    border-radius: 50%;
    border: 1px solid $custom-white;
    width: 50px;
    height: 50px;
    object-fit: cover;
    transition: all 0.3s ease-in-out;
  }

  &:is(:hover, :active) img {
    border: 1px solid $white;
  }
}

.cartIcon {
  display: flex;

  position: relative;

  cursor: pointer;

  @include ifdesktopM {
    display: none;

    margin-right: 52px;
  }
}

.mobileCartIcon {
  display: none;

  @include ifdesktopM {
    display: flex;

    z-index: 3;
  }
}

.cartAmount {
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  right: -10px;
  top: -8px;

  width: 20px;
  height: 20px;

  border-radius: 50%;

  background: $primary;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;

    color: $whiteDark;
  }
}

.mobileButton {
  padding: 9.5px 14px;

  span {
    font-family: $font-inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 25.39px;
    letter-spacing: -0.03em;

    text-transform: none;

    color: $black;
  }
}

.signUpButton {
  padding: 9.5px 14px;

  span {
    font-family: $font-inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 25.39px;
    letter-spacing: -0.03em;

    text-transform: none;

    color: $whiteBlue;
  }
}
