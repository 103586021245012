@use "../../../assets/scss/main.scss" as *;

.section {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;

  height: 100%;

  background: $primary-black;

  padding: 65px 20px 100px;

  z-index: 1;

  @include iftablet {
  }
}

.mainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  max-width: 600px;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 95px;
  height: 95px;

  border-radius: 50%;
}

.failedIconBg {
  background-color: #981a1733;
}

.successIconBg {
  background-color: #699f4c33;
}

.title {
  font-size: 48px;
  font-weight: 400;
  font-family: $font-kensmark;
  line-height: 52.8px;
  letter-spacing: -3.98783278465271px;

  text-transform: uppercase;

  text-align: center;

  color: $whiteDark;

  @include ifmobile {
    font-size: 32px;
    font-weight: 400;
    line-height: 35.2px;
    letter-spacing: normal;
  }
}

.description {
  font-family: $font-inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;

  text-align: center;

  opacity: 0.7;

  color: $whiteGray;

  @include ifmobile {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
  }
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
