@use "../../../../assets/scss/main.scss" as *;

.learningCard {
  display: flex;
  flex-direction: column;
  gap: 16px;

  position: relative;

  overflow: hidden;

  padding: 32px;
  border-radius: 12px;

  background: $primary;

  cursor: pointer;

  transition: all 0.1s ease-in-out;
  border: 1px solid transparent;

  &:is(:hover, :active) {
    transform: scale(1.1);
    border-color: #1e5674;
    box-shadow: 0px 0px 25px 0px #18506e;
    z-index: 2;
  }
}

.learningCardTitle {
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.5px;

  width: 100%;

  color: $whiteGray;

  @include ifmobile {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.5px;

    width: 100%;
  }
}

.learningCardDescription {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;

  font-weight: 400;
  line-height: 150%;
  opacity: 0.7;

  color: $whiteGray;
}

.icon {
  position: absolute;

  right: 0;
  top: 50%;
  transform: translateY(-50%);

  opacity: 5%;
}

.learningButton {
  margin-top: auto;
  margin-left: auto;
  padding: 5px 15px;
}
