@use "../../assets/scss/main.scss" as *;

.textarea-input-label {
  position: relative;
  box-sizing: border-box;
}

.textarea-input {
  display: block;
  background: transparent;
  color: $gray;

  margin: 0;
  resize: vertical;

  border-radius: 5px;

  width: 100%;
  box-sizing: border-box;

  appearance: none;

  &:focus {
    outline: 0;
  }
}

.textarea-input::placeholder {
  color: $whiteDark;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  opacity: 0.4;
}

.default {
  padding: 14px 35px;
  box-shadow: inset 0px 0px 0px 1px $white;

  color: $white;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.default:hover {
  box-shadow: inset 0px 0px 0px 2px $white;
}

.admin {
  padding: 10px 14px;

  color: $whiteGray;

  font-family: $font-inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;

  border-radius: 10px;
  border: 1px solid rgba(232, 232, 232, 0.3);
  box-shadow: none;
}

.admin:is(:hover, :focus) {
  border: 1px solid $whiteGray;
}

.admin::placeholder {
  font-family: $font-inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

.label,
.withoutValue {
  position: absolute;
  top: 9px;
  left: 14px;
  pointer-events: none;

  padding: 0 8px;
  white-space: nowrap;
  text-overflow: ellipsis;

  background: $blackLight;
  transition: all 0.2s ease-out;

  font-family: $font-inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;

  opacity: 0.5;

  color: $whiteGray;
}

.inputWrapper:focus-within .label,
.input:-webkit-autofill + .label,
.withValue {
  top: -10px;
  left: 8px;
  padding: 0 8px;
  border-radius: 1px;

  opacity: 1;
}

.text,
.withoutValue .text {
  font-size: 15px;
  line-height: 24px;
  transition: all 0.2s ease-out;
}

.inputWrapper:focus-within .text,
.input:-webkit-autofill + .label .text,
.withValue .text {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  opacity: 0.5;

  color: $whiteGray;

  transition: all 0.2s ease-out;
}

.label span {
  color: $white;
}
