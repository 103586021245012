@use "../../../../assets/scss/main.scss" as *;

.plansSection {
  position: relative;
}

.contentWrapper {
  display: grid;
  gap: 64px;
  overflow: hidden;

  position: relative;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  max-width: $main-container-width;
  width: 100%;

  padding: 0 15px;
  padding-top: 100px;

  @include iftablet {
    align-items: center;

    padding: 0 5%;
  }
}

.plansMain {
  display: grid;
  gap: 64px;

  position: relative;

  @include ifmobile {
    gap: 24px;
  }
}

.backgroundImage {
  position: absolute;
  top: 0;
  right: 5%;

  width: 570px;
  height: 300px;

  @include iftablet {
    right: 0;
    top: -70px;
  }

  @include ifmobile {
    width: 60%;
  }
}

.plansBackgroundImage {
  position: absolute;

  bottom: 150px;
  right: 0;

  @include ifdesktopM {
    img {
      width: 100%;
      height: 100%;
    }
  }

  @include ifmobile {
    order: 2;

    position: relative;
    bottom: -20px;
    left: 0;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.descriptionBlock {
  display: grid;
  gap: 24px;

  text-align: center;

  @include ifmobile {
    order: 1;
  }
}

.plansTitle {
  font-family: $font-kensmark;
  font-size: 64px;
  font-weight: 400;
  line-height: 70.4px;
  letter-spacing: -4px;
  text-align: center;

  z-index: 1;

  @include iftablet {
    letter-spacing: normal;
  }

  @include ifmobile {
    font-size: 32px;
    font-weight: 600;
    line-height: 35.2px;
    text-align: center;
  }
}

.plansDescription {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;

  opacity: 0.7;

  @include ifdesktopM {
    font-size: 16px;
  }

  @include ifmobile {
    font-size: 16px;
    line-height: 20.8px;
    text-align: center;
  }
}

.plansCardsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;

  z-index: 1;

  @include iftablet {
    flex-direction: column;
  }

  @include ifmobile {
    order: 3;
  }
}
