@use "../../../assets/scss/main.scss" as *;

.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;

  width: 50%;

  margin: 0 auto;

  @include iftablet {
    padding: 48px 0;
  }

  @include ifmobile {
    width: 100%;
  }
}

.loader {
  justify-self: center;
}

.selectWrapper {
  display: grid;
  gap: 24px;
}

.buttonWrapper {
  align-self: center;

  @include ifmobile {
    width: 100%;

    button {
      width: 100%;
    }
  }
}
