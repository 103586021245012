@use "../../../assets/scss/main.scss" as *;

.section {
  position: relative;

  flex: 1 0 auto;
}

.myCourseLoader {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 64px;

  box-sizing: border-box;

  margin: 48px auto;

  z-index: 1;

  max-width: $main-container-width;
  width: 100%;

  padding: 0 15px;

  @include ifdesktopM {
    max-width: $tablet-container-width;

    padding: 0 15px;
  }

  @include iftablet {
    max-width: $mobile-container-width;

    margin-top: 60px;
  }

  @include ifmobile {
    max-width: min(100%, 500px);
  }
}

.courseTypeWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  h6 {
    font-family: Inter;
    font-size: 22px;
    font-weight: 700;
    line-height: 33.6px;
    text-align: left;

    color: $whiteGray;
  }
}

.emptyCoursesWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
}

.emptyCoursesTitleWrapper {
  padding: 32px;

  p {
    font-family: $font-inter;
    font-size: 22px;
    font-weight: 500;
    line-height: 30.8px;
    text-align: center;

    color: $whiteGray;
  }

  @include ifmobile {
    padding: 0;

    p {
      font-size: 16px;
      line-height: 22.4px;
    }
  }
}

.coursesWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.courseTitle {
  font-family: $font-inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  text-align: center;

  color: $whiteGray;

  @include ifmobile {
    font-family: $font-main;
    font-size: 32px;
    font-weight: 600;
    line-height: 35.2px;
    text-align: center;

    text-transform: uppercase;
  }
}

.coursesList {
  display: flex;
  gap: 16px;

  @include ifmobile {
    flex-direction: column;
    align-items: center;
  }
}
