@mixin ifmobile {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin iftablet {
  @media only screen and (max-width: 1023px) {
    @content;
  }
}

@mixin ifdesktopM {
  @media only screen and (max-width: 1439px) {
    @content;
  }
}

@mixin ifdesktopL {
  @media only screen and (max-width: 1919px) {
    @content;
  }
}

@mixin ifdesktopXL {
  @media only screen and (min-width: 1920px) {
    @content;
  }
}
