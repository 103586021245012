@use "../../../assets/scss/main.scss" as *;

.section {
  position: relative;

  flex: 1 0 auto;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 48px;

  box-sizing: border-box;

  margin: 48px auto;

  z-index: 1;

  max-width: $main-container-width;
  width: 100%;

  padding: 0 15px;

  h5 {
    font-size: 32px;
    font-weight: 600;
    line-height: 24px;

    color: $white;

    @include ifmobile {
      font-family: $font-inter;

      font-size: 24px;
      font-weight: 700;
      line-height: 33.6px;
      text-align: left;
    }
  }

  @include ifdesktopM {
    max-width: $tablet-container-width;

    padding: 0 15px;
  }

  @include iftablet {
    max-width: $mobile-container-width;

    margin-top: 60px;
  }

  @include ifmobile {
    max-width: min(100%, 500px);

    gap: 16px;
  }
}

.profileWrapper {
  display: flex;
  gap: 162px;

  @include iftablet {
    flex-direction: column;

    gap: 48px;
  }
}

.profileAside {
  display: flex;
  flex-direction: column;
  gap: 64px;

  width: 100%;
}
