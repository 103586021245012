@use "../../../../assets/scss/main.scss" as *;

.reviewSection {
  display: grid;
  gap: 64px;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  max-width: $main-container-width;
  width: 100%;

  padding: 0 15px;

  z-index: 1;

  @include ifdesktopM {
    max-width: $tablet-container-width;
  }

  @include iftablet {
    max-width: $mobile-container-width;

    padding: 0 12px;
  }

  @include ifmobile {
    max-width: min(100%, 500px);

    gap: 32px;

    h3 {
      text-align: center;
    }
  }
}

.reviewTitle {
  font-family: $font-kensmark;
  font-size: 64px;
  font-weight: 400;
  line-height: 70.4px;
  letter-spacing: -4px;
  text-align: left;

  @include ifmobile {
    font-size: 32px;
    font-weight: 600;
    line-height: 35.2px;
    letter-spacing: normal;

    text-align: center;
  }
}

.reviewsWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;

  @include ifdesktopM {
    grid-template-columns: repeat(2, 1fr);
  }

  @include ifmobile {
    grid-template-columns: auto;
  }
}
