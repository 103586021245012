@use "../../../../assets/scss/main.scss" as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.courseInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px 0 10px;

  border-bottom: 1px solid $custom-white-gray;

  cursor: pointer;
}

.titleWrapper {
  display: flex;
  align-items: center;
  gap: 24px;
}

.title {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;

  text-transform: uppercase;

  color: $whiteGray;
}

.videos {
  padding: 0 4px;

  border-radius: 4px;

  background: $primary;

  white-space: nowrap;
}

.iconWrapper {
  display: flex;

  transition: transform 0.3s ease;
}

.iconWrapper.open {
  transform: rotate(180deg);
}

.mainContent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 124px;

  height: 0;
  overflow: hidden;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out,
    max-height 0.3s ease-in-out;

  @include ifdesktopM {
    grid-template-columns: auto;
  }
}

.mainContent.open {
  height: fit-content;
  opacity: 1;
  transform: scaleY(1);
}
