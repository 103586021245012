@use "../../assets/scss/main.scss" as *;

.footer {
  background: $blackLight;

  z-index: 1;
}

.footerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 100px;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  max-width: $main-container-width;
  width: 100%;

  padding: 75px 15px 50px;

  @include ifdesktopM {
    flex-direction: column;
    gap: 50px;

    max-width: $tablet-container-width;
  }

  @include iftablet {
    max-width: $mobile-container-width;

    padding: 50px 12px 30px;
  }

  @include ifmobile {
    max-width: min(100%, 500px);

    gap: 32px;
  }
}

.cardsWrapper {
  display: grid;
  gap: 20px;

  font-size: 14px;
  font-weight: 400;
  line-height: 23.4px;

  @include ifdesktopM {
    order: 1;
  }

  @include ifmobile {
    img {
      width: 100%;
    }
  }
}

.logo {
  width: 172px;
  height: 50px;
  object-fit: cover;

  @include ifmobile {
    display: none;
  }
}

.cardsImage {
  width: 97px;
  height: 31px;
}

.footerItemWrapper {
  display: flex;
  gap: 64px;

  width: fit-content;
  text-align: end;

  @include iftablet {
    flex-wrap: wrap;
    justify-content: center;
  }

  @include ifmobile {
    flex-direction: column;
    justify-content: center;
    gap: 40px;

    text-align: center;
  }
}

.title {
  font-size: 16px;
  font-weight: 400;
  line-height: 25.3px;

  margin-bottom: 20px;

  @include ifmobile {
    font-weight: 600;

    margin-bottom: 8px;
  }
}

.footerItems {
  display: grid;
  gap: 8px;

  @include ifmobile {
    gap: 0;
  }
}

.footerItem {
  display: grid;
  gap: 8px;

  text-align: right;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.4px;

  @include ifmobile {
    flex-wrap: wrap;

    text-align: center;
  }
}
