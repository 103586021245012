@use "../../../assets/scss/main.scss" as *;

.container {
  display: grid;
  gap: 200px;
  flex: 1 0 auto;

  overflow: hidden;

  @include iftablet {
    gap: 100px;
  }

  @include ifmobile {
    gap: 70px;
  }
}
