@use "../../assets/scss/main.scss" as *;

.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  min-width: 66px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 16px;
  opacity: 1;
  transition: opacity 0.2s ease;
  white-space: nowrap;
}

.button:is(:hover, :active) {
  opacity: 0.8;
}

.button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.button span {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  gap: 8px;
  // white-space: wrap;
  @include ifmobile {
    font-size: 11px;
  }
}

.small {
  line-height: 28px;
  padding: 4px 24px;
  width: fit-content;
}

.small span {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 700;
  font-size: 13px;
}

.medium {
  min-width: 66px;
}

.full {
  width: 100%;
}

.auto {
  width: auto;
}

.primary {
  background: $primary;
  color: $whiteGray;
}

.secondary {
  background-color: $whiteGray;
}

.secondary span {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  color: $primary;
}

.default {
  color: $whiteDark;
}

.outline {
  background: transparent;
}

.client {
  padding: 15px 35px;

  @include iftablet {
    padding: 15px;
  }
}

.admin {
  padding: 4px 16px;
  @include ifmobile {
    padding: 4px 8px;
  }
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
