@use "../../../../assets/scss/main.scss" as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  height: fit-content;

  padding: 32px 24px;

  background: $black;

  border-radius: 16px;
  border: 1px solid $primary;

  width: 350px;

  @include ifdesktopM {
    width: 310px;
  }

  @include iftablet {
    width: 350px;
  }

  @include ifmobile {
    width: 325px;
  }

  box-shadow: $plans-card-shadow;
}

.priceWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  text-align: center;
}

.newPrice {
  font-size: 40px;
  font-weight: 700;
  line-height: 36px;

  white-space: nowrap;

  color: $whiteDark;

  span {
    font-size: 24px;
    font-weight: 400;

    color: $custom-white-dark;
  }

  @include ifdesktopM {
    font-size: 28px;
  }
}

.diagonalLine {
  position: relative;
}

.diagonalLine::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 5%;
  width: 100%;
  height: 1px;
  background-color: $custom-white-dark;
  opacity: 1;
  rotate: -15deg;
}

.oldPrice {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;

  color: $custom-white-dark;
}

.annualContainer {
  padding: 56px 24px;

  background: $primary;
}

.title {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;

  opacity: 0.7;

  color: $whiteDark;
}

.price {
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  text-align: center;

  color: $white;

  white-space: nowrap;

  @include ifdesktopM {
    font-size: 32px;
  }
}

.pricePeriod {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: center;

  color: $custom-white-dark;
}

.descriptionWrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
}

.advantages {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.advantage {
  display: flex;
  align-items: center;
  gap: 12px;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;

    opacity: 0.7;

    color: $whiteDark;
  }
}
