@use "../../assets/scss/main.scss" as *;

.mainSection {
  position: relative;

  display: flex;
  flex-direction: column;
  height: 100%;
}

.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 64px;

  flex: 1 0 auto;

  background: $primary-black;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  margin-top: 81px;

  max-width: $main-container-width;
  width: 100%;

  padding: 32px 15px;

  @include ifdesktopM {
    max-width: $tablet-container-width;
  }

  @include iftablet {
    max-width: $mobile-container-width;

    padding: 0 12px;

    margin-top: 75px;
  }

  @include ifmobile {
    gap: 32px;

    max-width: min(100%, 500px);

    margin-bottom: 100px;
  }
}
