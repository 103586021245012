@use "../../../../assets/scss/main.scss" as *;

.section {
  display: flex;
  flex-direction: column;
  gap: 64px;

  position: relative;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  z-index: 1;

  max-width: $main-container-width;
  width: 100%;

  padding: 100px 15px 0;

  h4 {
    z-index: 1;
  }

  @include ifdesktopM {
    max-width: $tablet-container-width;
  }

  @include iftablet {
    max-width: $mobile-container-width;

    gap: 32px;
  }

  @include ifmobile {
    max-width: min(100%, 500px);

    padding: 0 15px;

    gap: 24px;
  }
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 15%;

  width: 570px;
  height: 300px;
}

.title {
  font-family: $font-kensmark;
  font-size: 64px;
  font-weight: 400;
  line-height: 70.4px;
  letter-spacing: -4px;
  text-align: left;

  text-transform: uppercase;

  color: $whiteDark;

  z-index: 1;

  @include iftablet {
    font-size: 48px;
    line-height: 48.2px;

    letter-spacing: normal;
  }

  @include ifmobile {
    font-size: 32px;
    line-height: 35.2px;
    text-align: center;

    letter-spacing: normal;
  }
}

.mainContent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 124px;

  @include ifdesktopM {
    grid-template-columns: auto;
  }
}

.buttonWrapper {
  display: flex;
  align-self: center;
}
