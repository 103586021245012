@use "../../../../assets/scss/main.scss" as *;

.modal {
  :global {
    @include ifmobile {
      .panel {
        border-radius: 0;
      }

      .panel-body {
        background: $primary;

        padding: 24px;
      }

      .panel-heading {
        background: $primary;

        padding: 0;
      }

      .panel-title {
        padding: 0;
      }
    }
  }

  @include ifmobile {
    height: calc(100% - 75px);
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;

  height: 100%;

  @include ifmobile {
    gap: 16px;
  }
}

.close {
  @include ifmobile {
    top: 14px;
    right: 22px;
  }
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 26.4px;
  text-align: center;

  word-break: break-word;

  color: $white;

  margin-bottom: 8px;
}

.newUserTitle {
  font-size: 24px;
  font-weight: 300;
  line-height: 26.4px;

  text-align: center;

  word-break: break-word;

  max-width: 320px;

  margin: 0 auto;

  color: $white;

  span {
    font-weight: 700;
  }
}

.forgotButton {
  display: flex;

  width: fit-content;

  cursor: pointer;

  font-family: $font-inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;

  color: $whiteDark;

  margin-top: -8px;
  margin-bottom: 8px;

  &:is(:hover, :active) {
    color: $white;

    opacity: 1;
  }
}

.avatarWrapper {
  display: grid;
  gap: 16px;
}

.fileWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.deleteIcon {
  cursor: pointer;
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  margin: 8px 0;

  word-break: break-word;

  p {
    font-family: $font-inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;

    color: $whiteGray;
  }

  a {
    color: $white;
    font-weight: 600;
    text-decoration: underline;
  }
}

.button {
  @include ifmobile {
    margin-top: auto;
  }
}
