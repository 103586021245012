@use "../../assets/scss/main.scss" as *;

.mainSection {
  position: relative;

  display: flex;
  flex-direction: column;
  height: 100%;
}

.mainContent {
  position: relative;
  margin-top: 81px;

  flex: 1 0 auto;

  @include iftablet {
    margin-top: 75px;
  }
}

.backgroundImage {
  position: absolute;
  top: 0;
  right: 0;
  width: 75%;
}

.backgroundArrowImage {
  position: absolute;
  bottom: 20%;
  right: 0;
  width: 100%;
}
