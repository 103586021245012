@use "../../../../assets/scss/main.scss" as *;

.modal {
  :global {
    .panel {
      @include ifmobile {
        border-radius: 0;
      }
    }

    .panel-body {
      padding: 24px;

      background: $blackLight;
    }

    .panel-heading {
      background: $blackLight;

      padding: 0;
    }

    .panel-title {
      padding: 0;
    }

    .close {
      display: flex;

      top: 36px;
      right: 24px;

      @include ifmobile {
        display: none;
      }
    }
  }

  border-radius: 8px;
  padding: 0;

  z-index: 2;

  box-shadow: $course-modal;

  @include ifmobile {
    height: calc(100% - 75px);
  }
}

.form {
  display: grid;
  gap: 32px;
}

.mainContent {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.backArrow {
  cursor: pointer;
}

.title {
  font-size: 26px;
  font-weight: 500;
  line-height: 33.8px;
  letter-spacing: -0.5px;
  text-align: left;

  text-transform: uppercase;

  color: $whiteGray;

  @include ifmobile {
    font-size: 20px;
    font-weight: 700;
    line-height: 26.4px;
    text-transform: none;
  }
}

.articleWrapper {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.article {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.articleInfo {
  display: flex;
  gap: 44px;

  @include ifmobile {
    gap: 16px;
  }
}

.pictureWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  min-width: 155px;

  @include ifmobile {
    gap: 2px;
  }

  img {
    width: 148px;
    height: 79px;

    object-fit: cover;

    border-radius: 12px;

    @include ifmobile {
      height: 86px;
    }
  }
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 12px;

  width: 148px;
  height: 79px;

  background: $custom-gray-dark;
}

.pictureButton {
  padding: 4px 8px;

  @include ifmobile {
    width: 100%;
    padding: 4px 8px;
  }
}

.inputsWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;
}

.inputWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.removeIcon {
  cursor: pointer;
}

.buttonsWrapper {
  display: flex;
  justify-content: center;
  gap: 12px;

  button {
    align-self: center;

    width: fit-content;

    @include ifmobile {
      width: 100%;
    }
  }
}
