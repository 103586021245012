@use "../../assets/scss/main.scss" as *;

.modal {
  position: absolute;

  @include ifmobile {
    height: calc(100% - 75px);
    bottom: 0;
  }

  z-index: 2;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.title {
  font-size: 36px;
  font-weight: 600;
  line-height: 50.4px;
  text-align: left;

  color: $whiteGray;
  @include iftablet {
    font-size: 24px;
  }
}

.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;

  word-break: break-word;

  color: $whiteGray;

  text-transform: none;

  opacity: 0.8;
}

.cartWrapper {
  display: flex;
  justify-content: space-between;
  gap: 32px;

  @include iftablet {
    flex-direction: column;
  }
}

.booksWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 100%;
}

.emptyCart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;

  text-align: center;

  h3,
  p {
    color: $whiteGray;

    word-break: break-word;
  }
}
