@use "../../../assets/scss/main.scss" as *;

.loader {
  display: flex;
  align-items: center;
  justify-content: center;

  flex: 1 0 auto;
}

.membershipSection {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 124px;

  margin-bottom: 100px;

  @include ifmobile {
    gap: 48px;
  }
}
