@use "../../../assets/scss/main.scss" as *;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  width: 100vw;
  height: 100vh;
}

.mainWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;

  @include ifmobile {
    height: 100%;

    padding: 80px 0 40px;
  }
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;

  h2 {
    font-weight: 600;
    line-height: 93.6px;

    @include ifmobile {
      font-size: 72px;
      font-weight: 600;
      line-height: 93.6px;
    }
  }

  p {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;

    color: $whiteDark;
  }

  @include ifmobile {
    flex-direction: column;
  }
}

.seperator {
  height: 100%;

  height: 58px;
  width: 2px;

  opacity: 0.5;

  background: $custom-white-gray-light;

  @include ifmobile {
    height: 2px;
    width: 100%;
  }
}

.button {
  @include ifmobile {
    margin-top: auto;

    width: 100%;
  }
}
