@use "../../../../assets/scss/main.scss" as *;

.container {
  display: flex;
  flex-direction: column;

  padding: 24px 16px;

  border: 1px solid $custom-white-gray;
  border-radius: 12px;

  @include ifmobile {
    cursor: pointer;
  }
}

.courseInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleWrapper {
  display: grid;
  grid-template-areas: "title icons button";
  align-items: center;

  column-gap: 48px;
  row-gap: 10px;
  justify-content: space-between;

  @include ifmobile {
    width: 100%;
    grid-template-areas:
      "title icons"
      "button button";

    column-gap: 18px;
    row-gap: 10px;
  }
}

.titleAndVideos {
  display: flex;
  align-items: center;
  gap: 16px;

  grid-area: title;
}

.title {
  font-size: 26px;
  font-weight: 500;
  line-height: 33.8px;
  letter-spacing: -0.5px;

  text-transform: uppercase;

  color: $whiteGray;

  @include ifmobile {
    font-size: 18px;
    line-height: 23.4px;
    text-align: left;
  }
}

.videos {
  padding: 0 4px;

  border-radius: 4px;

  background: $brown-gradient;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  white-space: nowrap;
}

.iconsWrapper {
  display: flex;
  align-items: center;
  gap: 48px;

  grid-area: icons;
}

.icons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.savingIconWrapper {
  display: flex;

  cursor: pointer;
}

.savingIconWrapper[aria-disabled="true"] {
  cursor: not-allowed;
}

.iconButton {
  display: flex;

  cursor: pointer;
}

.videoButton {
  padding: 4px 8.5px;
}

.activeEditingForm {
  opacity: 0.7;
}

.iconWrapper {
  display: flex;

  transition: transform 0.3s ease;

  cursor: pointer;
}

.iconWrapper.open {
  transform: rotate(180deg);
}

.mainContent {
  display: flex;
  flex-direction: column;
  gap: 24px;

  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.mainContent.open {
  max-height: 10000px;

  padding-top: 24px;
  z-index: 0;
}

.avatarWrapper {
  display: flex;
  align-items: center;
  gap: 24px;

  button {
    padding: 4px 16px;

    height: 32px;
    width: 100%;
    @include ifmobile {
      padding: 4px 8px;
    }
  }
}

.buttonWrapper {
  display: flex;
  justify-content: center;

  grid-area: button;
}

.pictureWrapper {
  img {
    width: 37px;
    height: 45px;

    border-radius: 12px;

    object-fit: cover;
  }
}

.inputWrapper {
  width: 164px;
}

.videosWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 60px;

  padding: 11px 16px;

  background: $black;

  border-radius: 12px;

  @include ifdesktopM {
    grid-template-columns: auto;
  }
}
