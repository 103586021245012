@use "../../../assets/scss/main.scss" as *;

.menu {
  position: absolute;
  right: 100px;
  top: 44px;

  display: flex;
  gap: 19px;

  background: $blackLight;

  box-shadow: $course-menu-item;

  border-radius: 12px;

  padding: 32px;

  z-index: 999;

  @include ifdesktopM {
    right: 0;
  }
}

.categoriesList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;

  width: 171px;

  @include ifdesktopM {
    width: fit-content;
  }
}

.category {
  font-size: 24px;
  font-weight: 500;
  line-height: 31.2px;
  letter-spacing: -0.5px;
  text-align: center;

  color: $whiteDark;

  opacity: 0.5;

  cursor: pointer;
}

.activeCategory {
  opacity: 1;
}

.coursesLoader {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 512px;
  height: 250px;
}

.coursesList {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  width: 812px;
  height: 314px;

  text-align: center;

  @include iftablet {
    width: 512px;
    height: 250px;
  }
}

h5 {
  line-height: 50.4px;
  text-align: center;

  @include iftablet {
    letter-spacing: normal;
  }
}
