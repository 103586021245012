@use "../../../assets/scss/main.scss" as *;

.container {
  display: grid;
  flex: 1 0 auto;
  overflow: hidden;
}

.aboutSection {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  max-width: 2000px;
  width: 100%;
  margin-bottom: 400px;

  @include ifdesktopM {
    margin-bottom: 300px;
  }

  @include iftablet {
    margin-bottom: 200px;
  }

  @include ifmobile {
    margin-bottom: -100px;
  }
}

.aboutImage {
  max-width: 100%;

  position: absolute;
  top: 0;
  right: 0;

  @include ifmobile {
    position: relative;
    margin-left: auto;
  }
}

.aboutWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 800px;
  margin-top: 200px;
  text-align: center;

  @include ifdesktopM {
    margin-top: 150px;
  }

  @include iftablet {
    max-width: 600px;
    margin-top: 100px;
  }

  @include ifmobile {
    position: absolute;
    top: 15%;
    padding: 0 12px;

    margin-top: 0;
  }
}

.aboutTitle {
  font-family: $font-kensmark;
  font-size: 72px;
  font-weight: 400;
  line-height: 93.6px;
  text-align: left;

  margin-bottom: 32px;

  @include ifdesktopM {
    line-height: 60px;
    font-size: 48px;
  }

  @include iftablet {
    line-height: 48px;
  }

  @include ifmobile {
    text-align: center;
    font-size: 28px;
    font-weight: 300;
    line-height: 33.6px;
    margin-bottom: 16px;
  }
}

.aboutDescription {
  font-size: 20px;
  font-weight: 500;
  line-height: 135%;

  @include iftablet {
    font-size: 16px;
  }

  @include ifmobile {
    text-align: center;
    font-weight: 400;
    line-height: 19.2px;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @include ifmobile {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.infoWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;

  @include ifmobile {
    position: absolute;
    top: 20%;
    max-width: 600px;
    padding: 0 12px;
  }
}

.imageBg {
  max-width: 100%;
  height: auto;
}

.imageLeftSide {
  @include ifmobile {
    margin-right: auto;
  }
}

.imageRightSide {
  @include ifmobile {
    margin-left: auto;
  }
}

.buttonWrapper {
  margin-top: 50px;

  @include ifmobile {
    margin-top: 20px;

    button {
      width: 100%;
    }
  }
}

.leftSection {
  padding-left: 5%;
  position: relative;

  @include ifmobile {
    padding-left: 0;
  }
}

.rightSection {
  padding-right: 5%;
  position: relative;

  @include ifmobile {
    padding-right: 0;
  }
}
