@use "../../../assets/scss/main.scss" as *;

.begginerSection {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;

  padding: 150px 0 100px 0;

  overflow: hidden;

  @include iftablet {
    padding: 100px 0 50px 0;
  }

  @include ifmobile {
    padding: 50px 0 20px 0;
  }
}

.begginerImageBg {
  position: absolute;
  top: -100px;
  left: -150px;
  max-width: 100%;

  @include ifdesktopM {
    top: -50px;
    left: -200px;
  }

  @include ifmobile {
    left: -250px;
    max-width: 300%;
  }
}

.vipImageBg {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 100%;

  @include ifmobile {
    max-width: 300%;
  }
}

.infoWrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 700px;
  text-align: center;

  @include ifmobile {
    padding: 0 20px;
  }
}

.courseTitle {
  font-family: $font-kensmark;
  font-size: 72px;
  font-weight: 400;
  line-height: 93.6px;

  margin-bottom: 32px;

  @include ifdesktopM {
    line-height: 60px;
    font-size: 48px;
  }

  @include iftablet {
    line-height: 48px;
  }

  @include ifmobile {
    font-size: 28px;
    font-weight: 300;
    line-height: 33.6px;

    margin-bottom: 20px;
  }
}

.courseDescription {
  font-size: 20px;
  font-weight: 500;
  line-height: 135%;

  @include iftablet {
    font-size: 16px;
  }

  @include ifmobile {
    font-weight: 400;
    line-height: 19.2px;
  }
}

.courseWrapper {
  position: relative;

  display: flex;
  gap: 20px;
  padding: 20px;
  background: #212121;
  border-radius: 12px;

  @include ifmobile {
    flex-direction: column;
  }
}

.reviewsWrap {
  padding: 100px 0;
}
