@use "../../assets/scss/main.scss" as *;

.modal {
  :global {
    .panel-body {
      padding: 32px 40px 24px;

      background: $white;
    }

    .panel {
      border-radius: 8px;
    }

    .panel-title {
      padding: 0;
    }

    .close {
      top: 24px;
      right: 12px;
      svg path {
        fill: $custom-secondary-black;
      }
    }
  }

  @include ifmobile {
    position: absolute;
    bottom: 40px;

    z-index: 100;
  }
}

.form {
  display: grid;
  gap: 48px;

  @include ifmobile {
    gap: 24px;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;

  padding: 16px;

  @include ifmobile {
    gap: 24px;
  }
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;

  color: $custom-black-dark;
}

.text {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;

  color: $neutral;

  word-break: break-word;
}

.buttonswWrapper {
  display: flex;
  gap: 16px;

  @include ifmobile {
    flex-direction: column;
    gap: 4px;
  }
}

.removingButton {
  border: none;

  span {
    font-family: Lexend;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;

    color: $black;
  }

  @include ifmobile {
    order: 1;
  }
}
