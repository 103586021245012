@use "../../assets/scss/main.scss" as *;

.modal {
  position: absolute;
  top: 65px;
  right: 5%;

  z-index: 100;

  background: $blackLight;

  :global {
    @include ifmobile {
      .panel-body {
        max-height: 60vh;
      }
    }
  }
}

.cartLoader {
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 200px;
}

.authModal {
  right: 25%;

  @include iftablet {
    right: 5%;
  }
}

.container {
  display: flex;
  flex-direction: column;
}

.cartProductsWrapper {
  display: flex;
  flex-direction: column;
  gap: 19px;

  padding-bottom: 19px;

  border-bottom: 1px solid $custom-white-light;
}

.cartProduct {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.cartProductInfo {
  display: flex;
  gap: 10px;

  img {
    width: 32px;
    height: 52px;

    object-fit: cover;
  }
}

.title {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;

  color: $whiteGray;

  word-break: break-word;

  span {
    opacity: 0.6;
  }
}

.pricesWrapper {
  text-wrap: nowrap;
}

.newPrice {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  color: $whiteGray;
}

.oldPrice {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  color: $grayLight;
}

.price {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  color: $whiteGray;
}

.diagonalLine {
  position: relative;
}

.diagonalLine::before {
  content: "";
  position: absolute;
  top: 50%;
  width: 100%;
  height: 1px;
  background-color: $white;
  opacity: 0.5;
  rotate: -20deg;
}

.diagonalLine {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  color: $grayLight;
}

.totalWrapper {
  display: flex;
  justify-content: space-between;

  padding: 19px 0;

  p {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;

    color: $whiteDark;
  }
}

.emptyCartText {
  padding-bottom: 16px;

  text-align: center;

  color: $whiteDark;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;

  button {
    padding: 14px 50px;

    span {
      font-weight: 600;
    }
  }
}
