@use "../../../assets/scss/main.scss" as *;

.container {
  display: grid;
  gap: 200px;
  flex: 1 0 auto;

  overflow: hidden;

  z-index: 1;

  @include iftablet {
    gap: 100px;
    margin-bottom: 50px;
  }

  @include ifmobile {
    gap: 64px;
    margin-bottom: 0;
  }
}

.courseLoader {
  display: flex;
  justify-content: center;
  align-items: center;

  height: calc(100vh - 312px);
  max-height: 800px;
  min-height: 600px;
}

.benefitsWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 64px;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  z-index: 1;

  max-width: $main-container-width;
  width: 100%;

  padding: 0 15px;

  @include ifdesktopM {
    max-width: $tablet-container-width;

    padding: 0 15px;
  }

  @include iftablet {
    max-width: $mobile-container-width;
  }

  @include ifmobile {
    grid-template-columns: auto;
    gap: 24px;

    max-width: min(100%, 500px);
  }
}
