@use "../../../assets/scss/main.scss" as *;

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 32px;

  border-radius: 12px;

  position: relative;

  padding: 18px;

  width: 260px;
  height: 314px;

  overflow: hidden;

  cursor: pointer;

  &:is(:hover, :active) .frontBlock {
    opacity: 1;
  }

  @include iftablet {
    width: 160px;
    height: auto;

    padding: 12px;

    gap: 12px;
  }

  @include ifmobile {
    width: 260px;
    height: 314px;

    gap: 16px;
  }
}

.rearBlock {
  position: absolute;
  top: 0;

  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }
}

.frontBlock {
  position: absolute;
  top: 0;

  width: 100%;
  height: 100%;

  background: $black;

  opacity: 0.8;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  z-index: 1;

  @include iftablet {
    justify-content: space-around;
    gap: 8px;

    height: 100%;
  }

  @include ifmobile {
    gap: 16px;

    height: auto;
  }
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.5px;

  text-align: center;

  white-space: pre-wrap;

  text-transform: uppercase;

  opacity: 0.8;

  color: $whiteGray;

  @include iftablet {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }

  @include ifmobile {
    font-size: 26px;
    font-weight: 500;
    line-height: 33.8px;
    letter-spacing: -0.5px;
  }
}

.pricesWrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  p {
    font-size: 32px;
    font-weight: 400;
    line-height: 41.6px;
    letter-spacing: -0.5px;

    text-align: center;

    color: $whiteGray;

    white-space: nowrap;

    @include iftablet {
      gap: 8px;

      font-size: 24px;
      font-weight: 400;
      line-height: 32.6px;
    }
  }

  @include ifmobile {
    flex-direction: column;
    gap: 4px;

    p {
      font-size: 40px;
      font-weight: 700;
      line-height: 52px;
      letter-spacing: -0.5px;
    }
  }
}

.diagonalLine {
  position: relative;
}

.diagonalLine::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: $whiteGray;

  opacity: 0.6;

  rotate: -10deg;
}

.pricesWrapper .oldPrice {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;

  opacity: 0.6;

  color: $whiteDark;
}

.info {
  width: fit-content;

  padding: 0 8px;

  border-radius: 4px;

  background: $black;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  z-index: 1;
}

.button {
  z-index: 1;
  padding: 15px 5px;
  span {
    white-space: wrap;
  }

  @include iftablet {
    padding: 8px 3px;
  }
}
