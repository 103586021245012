@use "../../../../assets/scss/main.scss" as *;

.questionsSection {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  margin: 0 auto;
  max-width: 2000px;
  width: 100%;

  position: relative;
}

.questionsWrapper {
  position: relative;

  display: flex;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  max-width: 1340px;
  width: 100%;

  padding: 150px 15px;

  @include ifdesktopM {
    max-width: $tablet-container-width;
  }

  @include iftablet {
    max-width: $mobile-container-width;

    padding: 20px 15px;
  }

  @include ifmobile {
    max-width: min(100%, 500px);

    justify-content: center;
  }
}

.backgroundArrowImage {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 70%;

  @include iftablet {
    width: 720px;

    transform: scale(2);
  }

  @include ifmobile {
    transform: scale(1);

    height: 100%;
  }
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: -200px;

  width: 1550px;
  height: 100%;

  @include iftablet {
    width: 950px;
  }

  @include ifmobile {
    display: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
