@use "../../../assets/scss/main.scss" as *;

.container {
  display: flex;
  gap: 12px;

  padding: 16px 32px;
  border-radius: 16px;
  border: 1px solid $primary;

  background: $black;

  box-shadow: $plans-card-shadow;

  img {
    width: 100px;
    height: 100%;

    object-fit: cover;
  }

  @include ifmobile {
    padding: 12px 16px 20px;
  }
}

.collectionImageWrapper {
  display: flex;

  position: relative;
}

.leftBooks {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  position: absolute;

  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;

  p {
    font-family: $font-inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;

    color: $whiteDark;

    @include ifmobile {
      font-size: 12px;
      line-height: 16.8px;
      text-align: left;

      span {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        font-weight: 700;
        font-size: 14px;
        line-height: 25.2px;
      }
    }
  }

  span {
    font-weight: 700;
  }
}

.priceAndQuantityWrapper {
  margin-left: auto;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  gap: 16px;
}

.pricesWrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  p {
    font-family: $font-inter;
    font-size: 28px;
    font-weight: 600;
    line-height: 39.2px;

    color: $whiteDark;
    text-wrap: nowrap;
  }

  @include ifmobile {
    flex-direction: column;
    gap: 4px;

    p {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
  }
}

.diagonalLine {
  position: relative;
}

.diagonalLine::before {
  content: "";
  position: absolute;
  top: 50%;
  width: 100%;
  height: 2px;
  background-color: $redDark;
  opacity: 1;
  rotate: -20deg;
}

.pricesWrapper .oldPrice {
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
}

.removeWrapper {
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 16px;
  font-weight: 400;
  line-height: 22px;

  color: $redDark;

  cursor: pointer;

  @include ifmobile {
    text-wrap: nowrap;
  }
}

.marksWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-left: 1px solid $black;

  width: 100%;
}
