@use "../../../../assets/scss/main.scss" as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 31px;

  width: 416px;

  @include ifmobile {
    gap: 16px;
  }
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.viewBlock {
  display: flex;
  flex-direction: column;
  gap: 24px;

  background: $black;

  border: 1px solid $primary;
  border-radius: 16px;

  padding: 60px 0 36px;

  box-shadow: $plans-card-shadow;

  text-align: center;

  @include ifmobile {
    padding: 24px 36px;
  }
}

.title {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;

  color: $whiteDark;
}

.priceWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  text-align: center;
}

.newPrice {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;

  color: $whiteDark;

  span {
    color: $custom-white-dark;
  }
}

.diagonalLine {
  position: relative;
}

.diagonalLine::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 5%;
  width: 100%;
  height: 1px;
  background-color: $custom-white-dark;
  opacity: 1;
  rotate: -15deg;
}

.oldPrice {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;

  color: $custom-white-dark;
}

.inputsPricesWrapper {
  display: flex;
  gap: 16px;

  label {
    background: $primary-black;
  }
}

.inputsBenefitsWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.inputWrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  button {
    padding: 4px 16px;
    @include ifmobile {
      padding: 4px 8px;
    }
  }

  label {
    background: $primary-black;
  }
}

.removeIcon {
  display: flex;

  cursor: pointer;
}

.buttonWrapper button {
  padding: 9px 16px;
}
