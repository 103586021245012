@use "../../../../assets/scss/main.scss" as *;

.container {
  display: flex;
  flex-direction: column;
  background-color: $whiteGray;
  color: $neutral;

  border-radius: 8px;
  padding: 40px;
}

.info {
  display: flex;
  align-items: center;
  gap: 16px;

  margin-bottom: 32px;
}

.reviewerImage {
  width: 32px;
  height: 32px;

  @include ifmobile {
    width: 36px;
    height: 36px;
  }
}

.reviewerName {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

.rating {
  display: flex;

  border-top: 1px solid $gray;
  padding: 32px 0 16px;
}

.reviewWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  gap: 16px;

  @include ifmobile {
    gap: 8px;
  }
}

.reviewText {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;

  color: $neutral;

  @include ifmobile {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
  }
}

.date {
  font-size: 14px;
  font-weight: 300;
  line-height: 150%;

  color: $grayLight;
}
