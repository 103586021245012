@use "./assets/scss/main.scss" as *;

.container {
  height: 100vh;

  color: $whiteDark;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
}
