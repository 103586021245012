@use "../../../../../assets/scss/main.scss" as *;

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;

  padding: 24px;

  background: $black;

  border-radius: 16px;
  border: 1px solid $primary;

  @include ifmobile {
    flex-direction: column;
    gap: 16px;

    width: 100%;

    padding: 24px 16px;
  }
}

.description {
  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;

    color: $whiteDark;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;

    opacity: 0.8;

    margin-left: 8px;
  }
}

.price {
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;

    color: $whiteDark;
  }

  span {
    opacity: 0.4;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
  gap: 4px;

  cursor: pointer;

  position: absolute;

  bottom: 12px;
  right: 12px;

  font-size: 12px;
}

.linkWrapper {
  display: flex;
  align-items: center;
  gap: 4px;

  cursor: pointer;

  position: absolute;

  bottom: 12px;
  right: 12px;

  font-size: 12px;
}
