@use "../../assets/scss/main.scss" as *;

.modal {
  :global {
    .panel-body {
      padding: 32px 40px 24px;

      background: $blackLight;
    }

    .panel {
      border-radius: 8px;
    }

    .panel-title {
      padding: 0;
    }

    .close {
      top: 16px;
      right: 6px;
    }
  }

  @include ifmobile {
    :global {
      .panel {
        border-radius: 0;
      }

      .panel-heading {
        background: $primary-black;

        padding: 0;
      }

      .panel-body {
        background: $primary-black;

        padding: 24px;
      }

      .panel {
        border-radius: 8px;
      }

      .panel-title {
        padding: 0;
      }

      .close {
        top: 16px;
        right: 6px;

        @include ifmobile {
          display: none;
        }
      }
    }
  }

  @include ifmobile {
    height: 100%;
  }
}

.videoPlayer {
  display: flex;

  width: 100%;
  height: 70vh;

  border-radius: 16px;

  overflow: hidden;

  @include ifmobile {
    flex-direction: column;
  }
}

.videoFrame {
  width: 100%;
  height: 100%;
}

.videoFrame {
  width: 100%;
  height: 100%;
}

.videoLoader {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
}

.emptyVideo {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
}
