@use "../../../../assets/scss/main.scss" as *;

.container {
  display: flex;
  gap: 24px;

  @include ifmobile {
    flex-direction: column;
    gap: 8px;

    padding: 16px 0;

    border-bottom: 1px solid $custom-white-light;
  }

  img {
    width: 105px;
    height: 92px;

    object-fit: cover;

    @include ifmobile {
      width: 105px;
      height: 62px;
    }
  }
}

.mainInfoWrapper {
  display: flex;
  gap: 24px;

  width: 100%;
}

.title {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -0.5px;

  color: $whiteGray;

  flex: 1;

  @include ifmobile {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
  }
}

.userBlogContainer {
  cursor: pointer;
}

.iconsWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.iconButton {
  display: flex;

  cursor: pointer;
}

.buttonsWrapper {
  display: flex;
  gap: 24px;

  button {
    padding: 4px;
  }
}
