@use "../../../../assets/scss/main.scss" as *;

.modalContainer {
  position: relative;
  display: flex;
  flex-direction: column;

  width: 61%;
  height: fit-content;

  margin: 40px 0;
  margin-left: auto;
  padding: 64px;
  border-radius: 24px;
  z-index: 2;

  background: $primary;

  @include iftablet {
    width: 75%;
  }

  @include ifmobile {
    width: 100%;

    padding: 48px 24px;
  }
}

.modalTitle {
  display: grid;
  gap: 24px;

  h3 {
    font-family: $font-kensmark;
    font-weight: 400;
    line-height: 70.4px;
    letter-spacing: -4px;
    text-align: left;

    @include ifmobile {
      line-height: 39.6px;
      text-align: left;
      letter-spacing: 0;
    }
  }

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;

    color: $custom-gray;

    @include ifmobile {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
    }
  }

  @include iftablet {
    h3 {
      letter-spacing: 0;
    }
  }
}

.formWrapper {
  display: grid;
  gap: 32px;

  padding: 32px 0;

  @include ifmobile {
    gap: 16px;
  }
}

.input {
  height: 55px;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;

  @include ifmobile {
    button {
      width: 100%;
    }
  }
}
