@use "../../../../assets/scss/main.scss" as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  order: -1;
}

.title {
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;

  color: $white;
}
