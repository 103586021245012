@use "../../../../assets/scss/main.scss" as *;

.benefitWrapper {
  display: flex;
  flex-direction: column;
  gap: 64px;

  position: relative;

  @include iftablet {
    gap: 32px;
  }

  @include ifmobile {
    gap: 24px;
  }
}

.backgroundImage {
  position: absolute;
  top: 40px;
  right: -20%;
  width: 20%;

  z-index: -1;

  display: none;

  @include iftablet {
    right: 0;
    top: 20px;
  }

  @include ifmobile {
    display: block;

    top: -30px;

    width: 50%;

    right: 5%;
  }
}

.title {
  font-family: $font-kensmark;
  font-size: 64px;
  font-weight: 400;
  line-height: 70.4px;
  letter-spacing: -4px;
  text-align: left;

  text-transform: uppercase;

  color: $whiteDark;

  @include iftablet {
    font-size: 48px;
    font-weight: 600;
    line-height: 48.2px;
  }

  @include ifmobile {
    font-size: 32px;
    font-weight: 600;
    line-height: 35.2px;
    text-align: center;

    letter-spacing: normal;
  }
}

.benefits {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.benfit {
  display: flex;
  align-items: center;
  gap: 12px;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;

    color: $whiteDark;

    opacity: 0.7;
  }
}
