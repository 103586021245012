@use "../../assets/scss/main.scss" as *;

.container {
  display: grid;
  gap: 24px;

  padding: 5%;

  position: relative;
  z-index: 1;

  font-weight: 300;

  a {
    color: $primary-blue;
  }

  ul > li {
    list-style: disc;
  }

  ol > li {
    list-style: decimal;
  }

  h5 {
    text-align: left;
    line-height: 1.5;
  }

  ul {
    max-width: 100%;
    list-style: none;
    padding-left: 20px;
    word-wrap: break-word;
    word-break: break-word;
  }

  ul li {
    position: relative;
    padding-left: 10px;
  }

  ul li::before {
    content: "•";
    position: absolute;
    left: -10px;
    color: #000;
    font-size: 14px;
  }
}

.header {
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 24px;

  font-weight: 600;
}
