@use "../../../../assets/scss/main.scss" as *;

.container {
  display: flex;
  align-items: center;

  margin: 0 auto;
  max-width: 2000px;
  width: 100%;

  height: calc(100vh - 212px);
  max-height: 800px;
  min-height: 700px;

  @include ifmobile {
    max-height: 450px;
    min-height: 400px;

    height: calc(100vh - 512px);
  }

  overflow: hidden;

  position: relative;
}

.imageWrapper {
  width: 100%;
  height: 100%;

  height: calc(100vh - 212px);
  max-height: 800px;
  min-height: 700px;

  position: absolute;

  @include ifmobile {
    max-height: 450px;
    min-height: 400px;

    height: calc(100vh - 512px);
  }

  img {
    left: 50%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    height: 100%;
  }
}

.backBlock {
  width: 100%;
  height: 100%;

  background: $black;

  opacity: 0.2;
  position: absolute;
}

.courseWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  width: 50%;
  height: fit-content;
  margin: 0 auto;

  padding: 64px;

  background: $black;

  border: 1px solid $primary;
  border-radius: 16px;

  box-shadow: $plans-card-shadow;

  z-index: 1;

  @include ifdesktopM {
    width: 67%;
  }

  @include iftablet {
    width: 70%;
  }

  @include ifmobile {
    width: 75%;

    padding: 24px 48px;
  }
}

.preTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  text-transform: uppercase;

  color: $white;

  @include ifmobile {
    font-size: 14px;
  }
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  @include ifmobile {
    gap: 24px;
  }
}

.title {
  font-family: $font-kensmark;
  font-size: 72px;
  font-weight: 400;
  line-height: 93.6px;
  text-align: left;

  text-align: center;

  color: $whiteDark;

  @include iftablet {
    font-size: 48px;
    line-height: 72px;
  }

  @include ifmobile {
    font-size: 28px;
    font-weight: 500;
    line-height: 36.4px;
  }
}

.videosWrapper {
  padding: 4px 16px;

  border-radius: 4px;

  background: $brown-gradient;

  font-size: 22px;
  font-weight: 400;
  line-height: 33px;

  color: $whiteGray;

  margin-top: -8px;

  @include ifmobile {
    font-size: 14px;
    line-height: 21px;
  }
}

.pricesWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  p {
    font-size: 40px;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: -0.5px;

    text-align: center;

    color: $whiteGray;

    white-space: nowrap;
  }

  @include ifmobile {
    flex-direction: column;
    gap: 4px;
  }
}

.diagonalLine {
  position: relative;
}

.diagonalLine::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: $whiteGray;

  opacity: 0.6;

  rotate: -10deg;
}

.pricesWrapper .oldPrice {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;

  opacity: 0.6;

  color: $whiteDark;
}
