@use "../../../assets/scss/main.scss" as *;

.emptyCoursesWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;

  margin: 80px 0 100px;
}

.emptyCoursesTitleWrapper {
  padding: 32px;

  p {
    font-family: $font-inter;
    font-size: 22px;
    font-weight: 500;
    line-height: 30.8px;
    text-align: center;

    color: $whiteGray;
  }

  @include ifmobile {
    padding: 0;

    p {
      font-size: 16px;
      line-height: 22.4px;
    }
  }
}

.coursesWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.courseTitleWrapper {
  @include iftablet {
    display: flex;
    justify-content: center;
    gap: 8px;
  }

  @include ifmobile {
    flex-direction: column;
    gap: 0;
  }
}

.courseTitle {
  font-family: $font-inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  text-align: center;

  color: $whiteGray;

  @include ifmobile {
    font-family: $font-main;
    font-size: 32px;
    font-weight: 600;
    line-height: 35.2px;
    text-align: center;

    text-transform: uppercase;
  }
}

.separator {
  height: 2px;
  width: 100%;

  background: $custom-white-gray-light;

  opacity: 0.3;
}

.coursesList {
  display: flex;
  gap: 16px;

  @include ifmobile {
    flex-direction: column;
    align-items: center;
  }
}
