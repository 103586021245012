@use "../../../assets/scss/main.scss" as *;

.blogSection {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 100px;

  flex: 1 0 auto;
  height: 100%;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  max-width: $main-container-width;
  width: 100%;

  margin-bottom: 200px;

  padding: 0 15px;

  z-index: 1;

  @include ifdesktopM {
    max-width: $tablet-container-width;

    padding: 0 15px;
  }

  @include iftablet {
    max-width: $mobile-container-width;

    padding: 0 60px;

    margin-top: 60px;
  }

  @include ifmobile {
    max-width: min(100%, 500px);

    padding: 0;
  }
}

.blogLoader {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
}

.noOneArticlesBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  height: 100%;

  text-align: center;
}
