@use "../../../../assets/scss/main.scss" as *;

.learningSection {
  position: relative;
}

.contentWrapper {
  display: grid;
  gap: 64px;

  position: relative;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  padding: 0 15px;

  max-width: $main-container-width;
  width: 100%;

  @include ifdesktopM {
    max-width: $tablet-container-width;
  }

  @include iftablet {
    max-width: $mobile-container-width;

    padding: 0 12px;
  }

  @include ifmobile {
    max-width: min(100%, 500px);
  }
}

.learningMain {
  display: grid;
  gap: 64px;

  padding-top: 100px;

  position: relative;

  @include iftablet {
    gap: 24px;

    padding-top: 0;
  }
}

.backgroundImage {
  position: absolute;
  top: 0;
  right: 5%;

  width: 570px;
  height: 300px;

  @include iftablet {
    right: 0;
    top: -70px;
  }

  @include ifmobile {
    width: 60%;
  }
}

.learingBackgroundImage {
  position: absolute;

  bottom: 150px;
  right: 0;

  @include ifdesktopM {
    img {
      width: 100%;
      height: 100%;
    }
  }

  @include ifmobile {
    order: 2;

    position: relative;
    bottom: -20px;
    left: 0;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.descriptionBlock {
  display: flex;
  flex-direction: column;
  gap: 24px;

  grid-column: 1 / -1;
  grid-row: 1;

  text-align: center;

  @include iftablet {
    grid-row: 1;
  }

  @include ifmobile {
    order: 1;
  }
}

.learningTitle {
  font-family: $font-kensmark;
  font-size: 64px;
  font-weight: 400;
  line-height: 70.4px;
  letter-spacing: -4px;
  text-align: center;

  z-index: 1;

  @include ifmobile {
    font-size: 32px;
    font-weight: 600;
    line-height: 35.2px;

    margin: 0 auto;

    letter-spacing: normal;

    text-align: center;
  }
}

.learningDescription {
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;

  padding: 0 165px;

  opacity: 0.7;

  @include ifdesktopM {
    font-size: 16px;
  }

  @include ifmobile {
    line-height: 20.8px;
    text-align: left;

    padding: 0;
  }
}

.learningCardsWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 48px;

  z-index: 1;

  @include iftablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include ifmobile {
    order: 3;

    grid-template-columns: auto;
  }
}
