@use "../../../assets/scss/main.scss" as *;

.coursesSection {
  display: flex;
  flex-direction: column;
  gap: 64px;

  @include ifmobile {
    gap: 48px;
  }
}

.courseTypeWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  h6 {
    font-family: Inter;
    font-size: 22px;
    font-weight: 700;
    line-height: 33.6px;
    text-align: left;

    color: $whiteGray;
  }
}

.courseHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include ifmobile {
    justify-content: center;
  }
}

.coursesList {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
