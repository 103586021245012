@use "../../../../assets/scss/main.scss" as *;

.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 100%;
}

.description {
  display: grid;
  gap: 8px;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;

    color: $whiteGray;
  }
}

.title {
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;

  color: $white;

  @include ifmobile {
    font-size: 18px;
    text-align: left;
  }
}

.inputWrapper {
  display: flex;
  gap: 24px;

  @include ifmobile {
    flex-direction: column;
  }

  label {
    background: $primary-black;
  }
}

.fileWrapper {
  display: grid;
  gap: 8px;

  & > p {
    color: $whiteGray;

    font-family: $font-inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 140%;
  }
}

.pictureWrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  img {
    width: 100px;
  }
}

.deleteIcon {
  cursor: pointer;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  p {
    color: $whiteGray;

    font-family: $font-inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;

    opacity: 0.7;
  }
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  gap: 16px;

  p {
    color: $whiteGray;
    font-family: $font-inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
  }
}

.timezone {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.profileFooter {
  display: flex;
  justify-content: flex-end;

  margin-top: -8px;

  @include ifmobile {
    button {
      width: 100%;
    }
  }
}
