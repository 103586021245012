@use "../../../../assets/scss/main.scss" as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.title {
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;

  color: $white;
}

.tableWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  position: relative;
}

.tableRowHead {
  border-radius: 14px;
  background: $blackLight;

  font-size: 14px;
  font-weight: 400;
  line-height: 140%;

  opacity: 0.6;
}

.tableRowBody {
  font-size: 16px;
  text-decoration: none;

  color: $whiteGray;
  font-family: $font-inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
}

.tableRowHead,
.tableRowBody {
  display: grid;
  grid-template-columns: 3fr 1fr 1.5fr 1fr;
  padding: 4px 16px;

  min-width: 500px;

  border-bottom: 1px solid $divider;
  transition: background-color 0.3s ease;

  @include ifmobile {
    grid-template-columns: 3fr 1fr;

    min-width: auto;

    grid-template-areas:
      "title price"
      ". status"
      ". date";
  }
}

.rowTitle {
  @include ifmobile {
    grid-area: title;
  }
}

.rowPrice {
  @include ifmobile {
    grid-area: price;
  }
}

.rowStatus {
  @include ifmobile {
    grid-area: status;
  }
}

.rowDate {
  @include ifmobile {
    grid-area: date;
  }
}

.tableRowHead {
  @include ifmobile {
    display: none;
  }
}

.orderItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
