@use "../../../../assets/scss/main.scss" as *;

.modal {
  :global {
    .panel {
      @include ifmobile {
        border-radius: 0;
      }
    }

    .panel-body {
      padding: 24px;

      background: $blackLight;
    }

    .panel-heading {
      background: $blackLight;

      padding: 0;
    }

    .panel-title {
      padding: 0;
    }

    .close {
      display: flex;

      top: 36px;
      right: 24px;

      @include ifmobile {
        display: none;
      }
    }

    @include ifmobile {
      height: calc(100% - 75px);
    }
  }

  border-radius: 8px;
  padding: 0;

  z-index: 2;

  box-shadow: $course-modal;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.mainContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 26px;
  font-weight: 500;
  line-height: 33.8px;
  letter-spacing: -0.5px;
  text-align: left;

  text-transform: uppercase;

  color: $whiteGray;

  @include ifmobile {
    font-size: 20px;
    font-weight: 700;
    line-height: 26.4px;
    text-transform: none;
  }
}

.videoWrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "video title"
    "video price"
    "video description"
    ". button";

  column-gap: 16px;
  row-gap: 24px;

  @include iftablet {
    row-gap: 8px;
  }

  @include ifmobile {
    grid-template-areas:
      "video title"
      "video price"
      "description description"
      "button button";
  }
}

.videoPlayerWrapper {
  grid-area: video;
}

.videoLoader {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 175px;
  height: 130px;

  @include ifmobile {
    width: 150px;
  }
}

.video {
  display: flex;
  flex-direction: column;
  gap: 4px;

  width: 175px;

  @include ifmobile {
    width: 150px;
  }
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;

  width: 100%;
  height: 89px;

  background: $custom-gray-dark;

  @include ifmobile {
    width: 148px;
    height: 86px;
  }
}

.uploadButtonWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: 155px;

  button {
    span {
      white-space: pre-wrap;

      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
    }
  }
}

.uploadProgressBarWrapper {
  position: relative;
  width: 100%;
  height: 5px;

  background-color: $primary-dark;

  border-radius: 20px;

  &:is(:hover, :active) .deleteUploadingFile {
    display: flex;

    position: absolute;
    top: 0;

    z-index: 1;

    opacity: 0.8;
  }
}

.uploadedTitle {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  z-index: 1;

  p {
    font-size: 12px;
  }
}

.uploadProgressBar,
.deleteUploadingFile {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;

  height: 100%;
  width: 100%;

  overflow: hidden;

  color: $white;
  font-size: 10px;

  border-radius: 20px;
  background-color: $primary;
}

.videoPlayer {
  width: 100%;
  height: 89px;

  border-radius: 5px;

  overflow: hidden;

  position: relative;
}

.videoFrame {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.videoFrame {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.uploadButton {
  padding: 4px 8.5px;

  gap: 0;

  @include ifmobile {
    width: 148px;
  }
}

.videoInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  font-family: $font-inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;

  color: $whiteGray;

  span {
    font-weight: 400;

    opacity: 0.8;
  }
}

.duration {
  font-family: $font-inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;

  color: $whiteGray;
}

.description {
  font-family: $font-inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;

  opacity: 0.7;

  color: $whiteGray;
}

.buttonsWrapper {
  display: flex;
  justify-content: center;
  gap: 12px;

  @include ifmobile {
    margin-top: auto;
  }

  button {
    align-self: center;

    width: fit-content;

    @include ifmobile {
      width: 100%;
    }
  }
}

.inputTitleWrapper {
  grid-area: title;
}

.priceInputsWrapper {
  grid-area: price;

  display: flex;
  gap: 8px;

  @include iftablet {
    flex-direction: column;
  }
}

.select {
  width: 100%;
}

.inputDescriptionWrapper {
  grid-area: description;
}

.videoScreenButton {
  padding: 0;

  span {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
  }
}

.fileWrapper {
  grid-area: button;

  display: flex;
  gap: 16px;

  @include ifmobile {
    justify-content: center;
  }
}

.pictureWrapper {
  position: relative;

  width: fit-content;

  display: flex;
  gap: 8px;

  @include ifmobile {
    width: 220px;
  }

  img {
    width: 40px;
    height: 28px;

    border-radius: 5px;

    overflow: hidden;

    object-fit: cover;
  }
}

.subtitlesWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
}
